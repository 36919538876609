@import "mixins";
// basic styles for form elements

.dark {
  button:not(.primary):not(.secondary) {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  button:not(.primary) {
    color: rgba(255, 255, 255, 0.75) !important;
  }

  button.secondary:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  eo-form-input .description,
  .form-field .description {
    color: rgba(255, 255, 255, 0.8);
  }

  .form-field .ui-inputtext,
  input {
    color: var(--color-white);
  }
  .form-field.disabled {
    background-color: rgba(255, 255, 255, 0.1);
  }
  // inner button like datepicker
  .form-field button.ui-button {
    background-color: rgba(255, 255, 255, 0.2);
    color: var(--color-white);
  }
  // checkbox
  .ui-chkbox .ui-chkbox-box {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 1);
  }

  .ui-inputtext {
    background-color: rgba(255, 255, 255, 0);
    color: var(--color-white);
  }

  // chips
  ul.ui-autocomplete-multiple-container li.ui-autocomplete-token,
  .ui-chips li.ui-chips-token {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: var(--color-white);

    .fa {
      font-size: 11px;
    }
  }
}

eo-form-input {

  yvc-chips-list {
    width: 100%;
  }

  .yvc-form-element[aria-disabled=true] {
    background-color: transparent;
  }

}
.form-element-range {
  // operator select in range form elements
  yvc-dropdown {
    background-color: var(--panel-divider-color);
    min-width: min-content;
    border: 0;
    cursor: pointer;
    yvc-icon {
      display: none;
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-ms-clear {
  display: none;
}

button.spinner {
  padding-right: 2em;
  position: relative;

  &::after {
    content: " ";
    position: absolute;
    margin: auto;
    top: calc(50% - 0.5em);
    right: 0.5em;
    width: 1em;
    height: 1em;
    display: inline-block;
    background: center center no-repeat;
    background-size: cover;
    animation: button-spin 1s infinite;
    box-sizing: border-box;
    border-width: 0.17em;
    border-color: currentColor currentColor currentColor transparent;

    border-radius: 50%;
    border-style: solid;
  }

  @keyframes button-spin {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(180deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

button,
a.button {
  text-decoration: none;
  border: 1px solid transparent;
  background: rgba(0, 0, 0, 0.06);
  color: var(--text-color-caption);
  padding: calc(var(--app-pane-padding) / 2) var(--app-pane-padding);
  cursor: pointer;
  @include transition();

  &:not([disabled]):not(.active):hover {
    border: 1px solid var(--color-primary-3);
    color: var(--text-color-caption);
    background: rgba(0, 0, 0, 0.1);
  }

  &:not(.primary):focus {
    border-radius: 2px;
    border-color: var(--color-primary-3);
    box-shadow: inset 0 0px 0 1px var(--color-white);
  }

  &.primary {
    color: var(--color-white);
    background: var(--color-accent);

    &:not([disabled]):hover {
      background: var(--color-accent-darker);
      color: var(--color-white);
    }

    &:not([disabled]):focus {
      border-radius: 2px;
      box-shadow: inset 0 0px 0 1px var(--color-white);
    }

    &:active {
      transform: translateY(1px);
    }

    &:not([disabled]):hover {
      opacity: 0.8;
    }

    &.grey {
      background: var(--color-primary-3);

      &:not([disabled]):hover {
        background: var(--color-light-gray);
      }

      &:not([disabled]):focus {
        border-radius: 2px;
        border-color: var(--color-primary-3);
        box-shadow: inset 0 0px 0 1px var(--color-white);
      }

      &:active {
        transform: translateY(1px);
      }
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;
      // background: var(--color-primary-3);
    }
  }
  // button without background
  &.secondary {
    background-color: transparent;
    &:not([disabled]):hover {
      background: rgba(0, 0, 0, 0.06);
    }

    &:focus {
      border-radius: 2px;
      border-color: transparent;
      box-shadow: inset 0 0px 0 1px var(--color-primary-3);
    }
    &:active {
      transform: translateY(1px);
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

.form-field {
  > * {
    flex: 1 1 auto;
  }
  .description {
    color: var(--text-color-caption);
  }
  &.disabled {
    .fe-wrapper {
      padding: calc(var(--app-pane-padding) / 8);
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.06);
      border-color: rgba(0, 0, 0, 0.06) !important;
    }
  }

  .ui-state-disabled {
    opacity: 0.8;
  }
  .ui-widget:disabled {
    opacity: 1;
    resize: vertical;
  }

  button.ui-button {
    background: rgba(0, 0, 0, 0.08);
    border: 0;
    border-radius: 2px;
    color: var(--text-color-caption);
    cursor: pointer;
    width: 20px;
    height: 20px;

    &:enabled:active,
    &:enabled:focus {
      border: 0;
      background: var(--color-accent);
      color: var(--color-white);
    }
    &.ui-spinner-button:enabled:hover,
    &:enabled:active,
    &:enabled:hover {
      border: 0;
      background: var(--color-accent);
      color: var(--color-white);
    }
    .fa {
      color: currentColor !important;
    }

    eo-icon {
      width: 16px;
      height: 16px;
      margin: auto;
    }
  }

  input {
    padding: 0;
    border: 0 !important;
    background: transparent !important;
    color: var(--text-color-body);
    display: flex;
    align-items: center;
  }
}

// tokens
ul.chips > li.chip {
    background: rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.54);
    margin: 2px 2px 0 0;
    display: flex;
    align-items: center;
  }

// chips in autocomplete
// .ui-chips-token .ui-chips-token-label,
// .ui-autocomplete-token-label {
//   @include flip-style(margin-right, 2em, margin-left, 0);
//   @include ellipsis();
// }

// .ui-chips-token .ui-chips-token-icon,
// .ui-autocomplete-token-icon {
//   @include flip-style(right, 0.2em, left);
//   @include flip-style(left, auto, right);
//   margin-top: -0.45em;
//   opacity: 0.5;

//   &:hover {
//     opacity: 1;
//   }
// }

// .ui-widget-overlay {
//   background: var(--color-black);
// }

// .ui-dialog .ui-dialog-content {
//   background: var(--color-white);
// }

// .ui-widget-header {
//   background: var(--color-white);
// }

// .ui-widget-header .ui-state-highlight,
// .ui-widget-content .ui-state-highlight,
// .ui-state-highlight {
//   border: var(--color-success);
//   background: var(--color-success);
// }

// .ui-corner-all {
//   border-radius: 0;
// }

// .ui-widget-header .ui-state-default a,
// .ui-widget-content .ui-state-default a,
// .ui-state-default a {
//   @include transition();
//   color: var(--text-color-caption);
// }

// .ui-widget-header .ui-state-active a,
// .ui-widget-content .ui-state-active a,
// .ui-state-active a {
//   color: var(--text-color-body);
// }

.fieldset {
  margin: calc(var(--app-pane-padding) / 4) calc(var(--app-pane-padding) / 2);
  padding: calc(var(--app-pane-padding) / 4);
  background: rgba(0, 0, 0, 0.025);
  border: 1px solid rgba(0, 0, 0, 0.1);

  .dark & {
    background: rgba(255, 255, 255, 0.025);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .legend {
    margin-bottom: calc(var(--app-pane-padding) / 2);

    > span {
      display: inline-block;
      font-size: var(--font-hint);
      border-radius: 2px;
      color: var(--color-white);
      background-color: rgba(0, 0, 0, 0.22);
      padding: calc(var(--app-pane-padding) / 8) calc(var(--app-pane-padding) / 4);
    }
  }
}

.empty .ui-dropdown-trigger {
  opacity: 0.3;
}

// dropdown
// .ui-dropdown {
//   width: 100%;
//   box-sizing: border-box;
//   padding: 0;
//   border: 0;
//   label{
//     background: transparent;
//   }

//   .ui-dropdown-trigger {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 2em;
//     border: 0;
//     color: var(--text-color-caption);
//   }

//   .ui-dropdown-panel {
//     background: var(--color-white);
//     border: 0;

//     .ui-dropdown-items {
//       padding: 0;

//       .ui-dropdown-item {
//         margin: 0;
//         padding: 0;

//         &.ui-state-highlight {
//           border: var(--color-accent);
//           background: var(--color-accent);
//           color: var(--color-white);
//         }
//       }
//       .ui-dropdown-item:not(.ui-state-highlight):hover {
//         background: var(--list-item-hover-background);
//       }
//     }
//   }
//   &:not(.ui-state-disabled):hover {
//     background: transparent;
//     border-color: var(--list-item-border-color);
//   }
// }

// autocomplete
// .ui-autocomplete {
//   width: 100%;

//   input.ui-inputtext {
//     width: 100%;
//     padding: 0;
//   }
// }

// // autocomplete dropdown
// .ui-widget-content.ui-autocomplete-panel {
//   background: var(--color-white);
//   border: 0;
//   box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);

//   //&::-webkit-scrollbar {
//   //  background: $color-primary-4;
//   //}

//   .ui-autocomplete-list {
//     padding: 0;
//   }

//   .ui-autocomplete-list-item {
//     color: var(--text-color-caption);
//     margin: 0;
//     display: flex;
//     align-items: center;
//     @include transition();
//     padding: calc(var(--app-pane-padding) / 2);

//     &.ui-state-highlight {
//       background: var(--list-item-hover-background);
//     }
//   }
// }

// ul.ui-autocomplete-multiple-container {
//   display: flex;
//   flex-flow: row wrap;

//   li.ui-autocomplete-input-token {
//     flex: 1 1 auto;
//     display: flex;
//     align-items: center;
//     padding: 0 4px;
//     margin: 2px 2px 0 0;
//   }

//   &.ui-inputtext {
//     padding: 0;
//   }
// }



// .ui-state-focus {
//   box-shadow: none !important;
// }

// .ui-inputtext.ui-state-focus,
// .ui-inputtext:focus {
//   box-shadow: none;
// }

// // tab styles
// .ui-tabview {
//   padding: 0;

//   .ui-tabview-nav li {
//     margin: 0 2px 1px 0;

//     a {
//       padding: calc(var(--app-pane-padding) / 4) calc(var(--app-pane-padding) / 2);
//       border-radius: 0;

//       .ui-tabview-title {
//         border-radius: 2px;
//         padding: 2px calc(var(--app-pane-padding) / 4);
//       }

//       &:focus {
//         .ui-tabview-title {
//           background: rgba(0, 0, 0, 0.06) !important;
//         }
//       }
//     }
//   }
//   .ui-tabview-panel {
//     //padding: var(--app-pane-padding);
//     padding: 0;
//   }
// }

// .ui-tabview.ui-tabview-top > .ui-tabview-nav {
//   padding: 0;
//   display: flex;
//   flex-flow: row wrap;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.15);

//   li {
//     border: 0;
//     top: 0;
//     box-sizing: border-box;
//     border-bottom: 3px solid transparent;
//     @include transition();

//     &.ui-state-default {
//       background: transparent;
//     }

//     &.ui-state-active {
//       border-color: var(--color-accent);

//       a {
//         cursor: default;
//       }
//     }
//   }
//   > li:not(.ui-state-active):not(.ui-state-disabled):hover {
//     background: transparent;
//   }
// }

// // special styles for sidebar tabs
// .ui-tabview.ui-tabview-top.tab-sidebar {
//   > .ui-tabview-nav li {
//     &.ui-state-active {
//       border-color: var(--color-white);

//       a {
//         color: var(--color-white);
//         cursor: default;
//       }
//     }
//   }
//   .ui-tabview-panel {
//     padding: 0;
//   }
// }

// // datepicker
// .ui-datepicker.ui-widget {
//   background: var(--color-white);

//   .ui-datepicker-calendar thead th {
//     background: var(--panel-background-lightgrey);
//   }
//   .ui-datepicker-calendar td a.ui-state-active {
//     background: var(--color-accent);
//     color: var(--color-white);
//   }
// }

// .ui-chkbox-box.ui-state-active,
// .ui-radiobutton-box.ui-state-active {
// }

// p-tristatecheckbox {
//   &.undefined {
//     .ui-chkbox .ui-chkbox-box {
//       border-color: transparent;
//     }
//   }
// }

// .ui-chkbox .ui-chkbox-box {
//   background-color: rgba(0, 0, 0, 0.06);
//   width: 18px;
//   height: 18px;
//   border-radius: 0;
//   line-height: 16px;
//   border: 2px solid rgba(0, 0, 0, 0.2);
//   color: var(--text-color-caption);

//   .fa {
//     @include no-transition();
//   }
//   .fa-close {
//     display: none;
//   }

//   &.ui-state-focus {
//     background-color: rgba(0, 0, 0, 0.06);
//     color: var(--color-accent);
//     border-color: var(--color-accent);
//   }
// }

// .ui-chkbox {
//   margin: 0;
//   position: relative; // fixed scrolling issue
// }

// // data table
// .ui-datatable {
//   .ui-datatable-data > tr > td.ui-editable-column {
//     padding: calc(var(--app-pane-padding) / 4);

//     &.ui-cell-editing {
//       padding: calc(var(--app-pane-padding) / 4);

//       .ui-cell-editor {
//         display: flex;
//         align-items: stretch;

//         input {
//           box-sizing: border-box;
//           background: var(--color-white);
//           padding: 4px;
//           border: 1px solid rgba(0, 0, 0, 0.1);
//         }
//       }
//     }
//   }
// }

// // tree
// .ui-tree {
//   border: 0;

//   .ui-treenode.ui-treenode-leaf > .ui-treenode-content > .ui-tree-toggler {
//     display: none;
//   }
// }
