@import 'node_modules/@eo-sdk/core/main.css';
@import 'node_modules/@angular/cdk/overlay-prebuilt.css';

@import 'node_modules/@ag-grid-community/core/dist/styles/ag-grid.css';
@import 'node_modules/@ag-grid-community/core/dist/styles/ag-theme-balham.css';
@import '/node_modules/@carbon/charts/styles.css';

// @import 'scss/eo-vars';
// @import 'scss/eo-theme';
@import 'scss/eo-variables';
@import 'scss/theme-dark';
@import 'scss/ag-grid';
@import 'scss/yuuvis-components';
@import 'scss/form';
@import 'scss/main';
@import 'scss/animation';
@import 'scss/timeline';
@import 'scss/migrationTemp';


*:focus {
  //border: 1px solid red !important;
}

// ngx-toastr overrides
#toast-container {

  &.toast-bottom-right {
    right: calc(var(--app-pane-padding) / 2);
    bottom: calc(var(--app-pane-padding) / 2 - 6px);
  }
  .ngx-toastr {
    animation: eoFadeInUp 500ms;
    margin: 0 0 calc(var(--app-pane-padding) / 2) 0;
    box-shadow: none;

    &.toast-info {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjJDRUZDQjIyNzgxNTExRTc5NzRBODJDODk0NjUwOEIzIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjJDRUZDQjIzNzgxNTExRTc5NzRBODJDODk0NjUwOEIzIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MkNFRkNCMjA3ODE1MTFFNzk3NEE4MkM4OTQ2NTA4QjMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MkNFRkNCMjE3ODE1MTFFNzk3NEE4MkM4OTQ2NTA4QjMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6O7GCSAAABJklEQVR42tRWQQ6CMBC0HnrqQU+c/AV/4GZ8AwfeQOJbOPkHXuEbeAB68cqlbmVqltIqQkriJpOa7u4MbLeLQmu9iWnbTWR7CwghRiBThIJQE1qCBlrsGZ8K5PZmSuQrE+3lhLv+biYm9+T3vK4A/ZaECyNoCGdCSkiAFHsNizM5coqAJe8IJU/yPKVETGdFPgqgLJb8OPUgTSwTyb0CBMVqXgaIrO08vpKdifIJFKzmcoaAZGdSWF5+D05YK2qzLlCNPfBwHcipHK7BG7RQT+deKnSXBteoRNaSDwTBEsGfsJhRiaLPohvWwwK+g8M1ELhizRYIZA5X/DZd76JNGRWGGBA/j4pVht0q4zr6B8cJUuiImo0SOwpq+FQg9wXx9/8qngIMAJK4HCJJ5zxGAAAAAElFTkSuQmCC");
      background-color: var(--color-primary-3);
    }

    &.toast-error {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMyNkE2QzM2NzgxNTExRTc4NzUxQkRGNUMyNTYxMkU0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMyNkE2QzM3NzgxNTExRTc4NzUxQkRGNUMyNTYxMkU0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzI2QTZDMzQ3ODE1MTFFNzg3NTFCREY1QzI1NjEyRTQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzI2QTZDMzU3ODE1MTFFNzg3NTFCREY1QzI1NjEyRTQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4SC8pQAAAA/ElEQVR42tRWQQqDMBA0PXjKoUW89xE+xHOPHvxYP+DJV/iInmOLFXpPZzFIMNGkbQx0YEA2uzshWUeZlDLZE4dkZ8wCjDGDAAdrsAUFKBWFitEaX6mdQEdkOybEKvAu3aCcylI/9V0K4DkFr/JzUE3qI/BN81lkU0Ady6+odAGm7Z4u9AZmG0NxBkdw2Mh5qLzXckwvjuaEERPydORkqpfxHpQBx7+0CRQBBQqbQB5QII9vFUDvkT9IP3fsbQJdwI13NoHGo5Dm++SR1ximBHIPczuqGpf58WhWEdfsotj17h+cRRJdVg22oNCaChWjNb5Sa9j1f/5VvAUYAP5g6KaK4+v2AAAAAElFTkSuQmCC");
      background-color: var(--color-error);
    }
    &.toast-success {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjI3QjVDOTRDNzgxNTExRTdCQzQyRUFFREM3MkQyODBFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjI3QjVDOTRENzgxNTExRTdCQzQyRUFFREM3MkQyODBFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjdCNUM5NEE3ODE1MTFFN0JDNDJFQUVEQzcyRDI4MEUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjdCNUM5NEI3ODE1MTFFN0JDNDJFQUVEQzcyRDI4MEUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4MncpyAAAAxklEQVR42mL6//8/Ay0xEwONAd0tCAfiN0D8n0z8BmoGHDCCwgkJgBQIU+jot0AsgssHwlQIFWFaxMFdWkbyZiDWAuJOWlgAMjyEkZHxF04VaBmDFLAJiNmgZnSgycHNxGfBIzINJ8qC6UDMDjWIVMOJtgCUR9jQLCHGcKKDCN0SYg0n2gJ0S4g1nCQL4JaQYDiKBSxEpPUMqOEfgVQ5qRkFvbD7T6Wig3HA6oO3VDDzLT4Lsim05C3UDJxxMPTrZKoDgAADAHmdtFL1VnAuAAAAAElFTkSuQmCC");
      background-color: var(--color-success);
    }
    &.toast-warning {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjM3MDRGMjZDNzgxNTExRTc5QTVFRUMwRTVCNzdFQ0I4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjM3MDRGMjZENzgxNTExRTc5QTVFRUMwRTVCNzdFQ0I4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzcwNEYyNkE3ODE1MTFFNzlBNUVFQzBFNUI3N0VDQjgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzcwNEYyNkI3ODE1MTFFNzlBNUVFQzBFNUI3N0VDQjgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5IOtrLAAABAUlEQVR42mL8//8/Ay0BEwOtASk+AKoNB2GSzCbWAqA6TiB+BMQPQWxaWNDwHwHqqWoBUI0cEH9DsgDElqWmBSv+Y4LlVLEAKG/7HzewocgCoBwTEJ/DYwFIjokSC1L/EwYpZFkAFOcH4ldohskDsQCa2EuQWnIs6MXiWgGoHDroIckCoJg6EP8iwQKQWjVSLNiGI7xxWQACW4myAMj3/E8+8MRrAZDNCsQ3KbDgBsgMZAvQ03AuEKvhyTsKQCyIR14diHOwFtdAWgyIPxJwIb44gIEPILMwgghIz/pPPTATxQIgNgTif1S0AGSWAbIFh/5TH4DMZGAc8pU+zS0ACDAAdvOwZPPEHhwAAAAASUVORK5CYII=");
      background-color: var(--color-warning);
    }
  }
}

// global fot face definition
@font-face {
  font-family: 'eoRoboto';
  src: url('assets/_default/fonts/roboto_regular_macroman/Roboto-Regular-webfont.eot');
  src: url('assets/_default/fonts/roboto_regular_macroman/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/_default/fonts/roboto_regular_macroman/Roboto-Regular-webfont.woff') format('woff'),
  url('assets/_default/fonts/roboto_regular_macroman/Roboto-Regular-webfont.ttf') format('truetype'),
  url('assets/_default/fonts/roboto_regular_macroman/Roboto-Regular-webfont.svg#robotoregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'eoRoboto';
  src: url('assets/_default/fonts/roboto_light_macroman/Roboto-Light-webfont.eot');
  src: url('assets/_default/fonts/roboto_light_macroman/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/_default/fonts/roboto_light_macroman/Roboto-Light-webfont.woff') format('woff'),
  url('assets/_default/fonts/roboto_light_macroman/Roboto-Light-webfont.ttf') format('truetype'),
  url('assets/_default/fonts/roboto_light_macroman/Roboto-Light-webfont.svg#robotolight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'eoRoboto';
  src: url('assets/_default/fonts/roboto_medium_macroman/Roboto-Medium-webfont.eot');
  src: url('assets/_default/fonts/roboto_medium_macroman/Roboto-Medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/_default/fonts/roboto_medium_macroman/Roboto-Medium-webfont.woff') format('woff'),
  url('assets/_default/fonts/roboto_medium_macroman/Roboto-Medium-webfont.ttf') format('truetype'),
  url('assets/_default/fonts/roboto_medium_macroman/Roboto-Medium-webfont.svg#robotomedium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'eoRoboto';
  src: url('assets/_default/fonts/roboto_bold_macroman/Roboto-Bold-webfont.eot');
  src: url('assets/_default/fonts/roboto_bold_macroman/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/_default/fonts/roboto_bold_macroman/Roboto-Bold-webfont.woff') format('woff'),
  url('assets/_default/fonts/roboto_bold_macroman/Roboto-Bold-webfont.ttf') format('truetype'),
  url('assets/_default/fonts/roboto_bold_macroman/Roboto-Bold-webfont.svg#robotobold') format('svg');
  font-weight: 700;
  font-style: normal;
}

body, input, textarea {
  font-family: eoRoboto, sans-serif;
  font-size: var(--font-body);
}

:focus {
  outline: 0;
}

// scrollbars
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  //background-color: #d0d0d0;
  background-color: #ddd;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

// scrollbar color for dark backgrounds
.dark {
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .2);
  }
}

