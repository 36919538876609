@import "mixins";
// some global styles

//*:focus {
//  border: 1px solid red !important;
//  background-color: red;
//}

*[hidden] {
  display: none !important;
}

*::selection {
  background: rgba(var(--color-accent-rgb), 0.3);
}

.dark {
  *::selection {
    background: rgba(var(--color-white-rgb), 0.2);
  }
}

.eo-dialog empty {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: var(--text-color-caption);
}

.backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: none;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: eoFadeIn calc(var(--app-default-transition-duration) * 2);
}

.eo-login .ui-dropdown {
  .ui-inputtext {
    //padding: 0 var(--app-pane-padding) 0 0;
    cursor: text;
    padding: calc(var(--app-pane-padding) * 0.75)
      calc(var(--app-pane-padding) / 2);
  }
  .ui-dropdown-item {
    .option-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      @include flip-style(
        padding-right,
        calc(var(--app-pane-padding) / 2),
        padding-left
      );
      > div {
        flex: 1 1 auto;
        padding: calc(var(--app-pane-padding) / 2);
      }
      eo-icon {
        flex: 0 0 auto;

        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

.ui-overlaypanel {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
}

// toggle buttons
button.toggle {
  background: rgba(0, 0, 0, 0.1);
  color: var(--text-color-caption);
  border-radius: 2px;
  padding: 0.5em 1em;
  cursor: pointer;

  &.active {
    background: var(--color-accent);
    color: var(--color-white);
    cursor: default;
  }

  &:not(.active).dark {
    background: rgba(255, 255, 255, 0.1);
    color: var(--color-white);
  }
}

textarea,
input {
  &:-ms-input-placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.3) !important;
    font-style: italic;
    font-size: var(--font-caption);

    .dark & {
      color: rgba(255, 255, 255, 0.3) !important;
    }
  }
}

.chip {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  @include border-radius();
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 3px;
  @include flip-style(margin-right, 2px, margin-left);
  color: var(--text-color-caption);
  margin: 1px 0;

  .type-icon {
    opacity: 0.4;
    height: 18px;
    width: 18px;
    vertical-align: text-bottom;
  }
}

a.link {
  display: inline-block;
  opacity: 0.5;
  @include transition();
  svg.ref-icon {
    height: 18px;
    fill: currentColor;
    vertical-align: bottom;
  }
  &:hover {
    opacity: 1;
    color: var(--color-accent);
  }
}

svg.checkbox {
  fill: var(--text-color-caption);
  vertical-align: bottom;

  .background {
    fill: rgba(0, 0, 0, 0.1);
  }
}

.flex-row {
  @include flex-row();
}

.flex-col {
  @include flex-col();
}

.eospp {
  @extend .flex-col;
  @include fade-in();
}

.eo-head {
  position: relative;
  height: var(--app-pane-header-height);
  box-sizing: border-box;
  padding: var(--app-pane-padding) var(--app-pane-padding) 0
    var(--app-pane-padding);
  @include flex-col(none);

  &.eo-head-tabs {
    height: calc(
      var(--app-pane-header-height) - var(--eo-tab-nav-height)
    ); // substract the height of the tabs
    background-color: var(--color-white);
  }

  .eo-header {
    @include flex-row();
    color: var(--text-color-caption);

    .eo-header-icon {
      padding: calc(var(--app-pane-padding) / 4) 0;
    }

    .eo-header-info {
      @include flex-col();
      padding-right: var(--app-pane-padding);
      padding-left: var(--app-pane-padding);
      user-select: none;

      .eo-header-title {
        @include ellipsis();
        padding: calc(var(--app-pane-padding) / 4) 0;
        font-size: var(--font-title);
        font-weight: normal;
        line-height: 1.1em;
        margin: 0;
      }
      .eo-header-subtitle {
        @include ellipsis();
        font-size: var(--font-subhead);
        font-weight: normal;
        line-height: 2em;
        height: 2em;
        margin: 0;

        &__label {
          padding-right: 5px;
          text-overflow: ellipsis;
        }
      }
    }

    .eo-header-actions {
      @include flex-row(none);
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: flex-start;

      eo-icon {
        padding: calc(var(--app-pane-padding) / 4);
      }
    }
  }

  .header-info {
    flex: 1 1 auto;
    overflow: hidden;
    display: block;
    order: 1;
    color: var(--text-color-caption);

    .status {
      position: absolute;
      right: var(--app-pane-padding);
      bottom: calc(var(--app-pane-padding) / 2);
      display: flex;
      flex-flow: row nowrap;

      eo-icon.state,
      span {
        flex: 0 0 auto;
        @include border-radius(2px);
        margin-left: 2px;
      }

      span.label {
        display: inline-block;
        font-size: var(--font-caption);
        padding: 3px 5px;
        line-height: 1em;
        color: var(--text-color-caption);
      }
      span.label.finalized {
        background: var(--color-accent);
        color: var(--color-white);
      }
      eo-icon.state {
        background: var(--color-primary);
        padding: 0 2px;
        fill: var(--color-white);
        width: calc(var(--font-caption) + 6px);
        height: calc(var(--font-caption) + 6px);
      }
    }
  }

  .header-title,
  .header-sub-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // nested elements of the app pane header
  .header-title {
    font-size: var(--font-title);
    flex: 1 1 auto;
    order: 1;
    padding-bottom: 0.25em;
  }

  .header-sub-title {
    font-size: var(--font-caption);
    color: var(--text-color-caption);
    flex: 1 1 auto;
    order: 2;
  }

  .actions {
    order: 2;
    flex: 0 0 auto;
    padding: 0;

    eo-icon {
      color: var(--text-color-caption);
      @include transition();
      @include border-radius();

      &:not(.disabled):hover {
        background: rgba(0, 0, 0, 0.06);
      }
    }

    .menu {
      cursor: pointer;
    }

    span.btn {
      @include transition(var(--app-default-transition-duration));
      @include border-radius(2px);
      background-color: var(--main-background);
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      display: inline-block;
      margin-left: 3px;
    }

    span.btn svg {
      fill: var(--text-color-caption);
    }

    span.btn:hover {
      background-color: var(--color-accent);

      svg {
        fill: var(--color-white);
      }
    }
  }

  .metaActions {
    bottom: 0;
    font-size: var(--font-caption);
    left: 0;
    position: absolute;
    right: 0;

    .btn {
      cursor: pointer;
      display: inline-block;
      padding: 5px 10px;
    }

    .btn.open {
      background-color: var(--color-accent);
      color: var(--color-white);
    }

    .text {
      color: var(--text-color-caption);
    }
  }
}

.eo-body {
  overflow-y: auto;
  position: relative;
  @extend .flex-col;

  .ui-tabview.ui-tabview-top > .ui-tabview-nav {
    border-color: var(--panel-header-border-bottom-color);
  }
}

.no-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.ui-widget {
  font-family: "eoRoboto", sans-serif;
}
.ui-widget-content {
  background: transparent;
}
.ui-overlaypanel {
  border: 0;
}
.ui-dialog {
  border: 0;
}
