// yvc-dialog requires a 100% height on body element
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.cdk-overlay-pane {
  yvc-overlay {
    width: 100%;

    .yvc-modal-content {
        border:0;
    }
  }
}

yvc-tabs {
  > header {
    --yvc-tab-nav-button-margin: 1px;
    > .scroll-x nav > a {
      padding: calc(var(--app-pane-padding) / 4) !important;
    }
  }
}

yvc-chips-list ul.chips li.chip {
  background: var(--eo-chips-background-color);
  border: 1px solid var(--eo-chips-border-color);
  border-radius: 2px;
  color: var(--eo-chips-text-color);
  margin: 2px 2px 0 0;
  display: flex;
  align-items: center;
}
yvc-autocomplete-options .org-item {
  display: flex;
  align-items: center;
}
