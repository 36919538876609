// basic styles for timelines
$app-pane-padding: 16px;
$timeline-marker-width: calc(var(--app-pane-padding) * 2);
$timeline-marker-color: var(--text-color-hint);

.eo-timeline {

  // just one item
  &.single {
    .timeline-entry .marker:after {
      display: none;
    }
  }

  .timeline-entry {
    display: flex;
    flex-flow: row;
    flex: 0 0 auto;

    &:first-child {
      .marker:after {
        height: 50%;
        top: auto;
      }
    }
    &:last-child {
      .marker:after {
        height: 50%;
        bottom: auto;
      }
    }

    .when {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      align-self: center;
      flex: 0 0 auto;
      min-width: 70px;

      .date {
        color: var(--text-color-body);
      }
      .time {
        font-size: var(--font-caption);
      }
    }
    .marker {
      width: calc(var(--app-pane-padding) * 2);
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      margin: 0 var(--app-pane-padding);

      &:before {
        content: '';
        width: var(--app-pane-padding);
        height: var(--app-pane-padding);
        border-radius: 50%;
        background-color: var(--color-white);
        border: 2px solid var(--text-color-hint);
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        width: calc(var(--app-pane-padding) - 1px);
        left: 0;
        top: 0;
        bottom: 0;
        border-right: 2px solid rgba(var(--color-black-rgb), 0.26);
      }
    }
    .what {
      background-color: var(--color-white);
      border-radius: 2px;
      padding: calc(var(--app-pane-padding) / 2);
      margin: calc(var(--app-pane-padding) / 2) calc(var(--app-pane-padding) / 2) 0 0;

      .title {
        color: var(--text-color-body);
        font-weight: var(--font-weight-bold);
        margin-bottom: calc(var(--app-pane-padding) / 2);
      }
      .description {
        margin-bottom: calc(var(--app-pane-padding) / 2);
      }
      .meta {
        color: var(--text-color-caption);
        margin-bottom: calc(var(--app-pane-padding) / 2);
      }
    }
  }
}
