@import "mixins";

.ag-theme-balham, .ag-theme-balham .ag-header {

  font-family: eoRoboto, sans-serif;

  .ag-root {
    border: none;
  }

  .ag-row-selected {
    background: var(--list-item-selected-background);
    border-color: var(--list-item-border-color);
  }

  .ag-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    opacity: .87;
  }

  .ag-icon-menu {
    background-image: url("data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%2224%22 height%3D%2224%22 viewBox%3D%220 0 24 24%22%3E%3Cpath d%3D%22M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z%22%2F%3E%3C%2Fsvg%3E") !important;
    &:before {
      opacity: 0; // hide original icons
    }
  }

  .ag-icon-filter {
    background-image: url("data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%2224%22 height%3D%2224%22 viewBox%3D%220 0 24 24%22%3E%3Cpath d%3D%22M3 2h18v2h-.08L15 9.92v12.99l-6-6v-7L3.09 4H3V2m8 14.08l2 2V9h.09l5-5H5.92l5 5H11v7.08z%22%2F%3E%3C%2Fsvg%3E") !important;
    &:before {
      opacity: 0; // hide original icons
    }
  }

  .ag-icon-asc {
    background-image: url("data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%2224%22 height%3D%2224%22 viewBox%3D%220 0 24 24%22%3E%3Cpath d%3D%22M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z%22%2F%3E%3C%2Fsvg%3E") !important;
    &:before {
      opacity: 0; // hide original icons
    }
  }

  .ag-icon-desc {
    background-image: url("data:image/svg+xml,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%2224%22 height%3D%2224%22 viewBox%3D%220 0 24 24%22%3E%3Cpath d%3D%22M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z%22%2F%3E%3C%2Fsvg%3E") !important;
    &:before {
      opacity: 0; // hide original icons
    }
  }

  .ag-icon-checkbox-checked:empty {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMEgyYTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmgxNGEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMnpNNyAxNEwyIDlsMS40MS0xLjQxTDcgMTEuMTdsNy41OS03LjU5TDE2IDVsLTkgOXoiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
    &:before {
      opacity: 0; // hide original icons
    }
  }

}

