:root {
  --color-accent-tone: #fff;

  --main-background: #e5e5e5;
  --main-background-light: #eeeeee;

  // TODO: Replace by meaningfull values
  --color-white: #fff;
  --color-white-rgb: 255, 255, 255;
  --color-black: #000;
  --color-black-rgb: 0, 0, 0;
  // TODO: Replace by meaningfull values

  --color-primary: #484f54;
  --color-primary-2: #6d7276;
  --color-primary-3: #919598;
  --color-primary-4: #b6b9bb;  
  --color-primary-rgb: 72, 79, 84;

  --color-accent-rgb: 196, 34, 122;
  --color-accent: #c4227a;
  --color-accent-light: #cf4a92;
  --color-accent-darker: #e08cba;
  
  --color-error: #ed5564;
  --color-success: #9abd1e;
  --color-light-gray: #919598;
  --color-warning: #f39200;
  --color-favorite: #ffc107;
  --color-dirty-background: #ebf7d2;
  --color-dirty-font: #8fad1c;

  // colors
  --panel-background: #fff;
  --panel-background-grey: #f4f4f4;
  --panel-background-lightgrey: #fafafa;
  --panel-header-border-bottom-color: rgba(0, 0, 0, 0.12);
  --panel-divider-color: var(--panel-header-border-bottom-color);
  --list-item-hover-background: #eee;
  --list-item-selected-background: #f5f5f5;
  --list-item-border-color: rgba(0, 0, 0, 0.12);
  --text-color-body: rgba(0, 0, 0, 0.87);
  --text-color-caption: rgba(0, 0, 0, 0.54);
  --text-color-hint: rgba(0, 0, 0, 0.26);
  --text-color-accent: var(--color-accent);

  --state-green: #8bc34a;
  --state-orange: #ffc107;
  --state-purple: #9c27b0;
  // font sizes and weights
  --font-hint: 11px;
  --font-caption: 12px;
  --font-body: 13px;
  --font-subhead: 15px;
  --font-title: 20px;
  --font-headline: 24px;
  --font-display: 34px;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  // sizes
  --app-pane-padding: 16px;
  --app-bar-height: 64px;
  --app-pane-header-height: 140px;
  --app-split-pane-divider-size: var(--app-pane-padding);
  --app-default-transition-duration: 200ms;

  --eo-tab-nav-height: 30px;
  --eo-chips-background-color: rgba(0, 0, 0, 0.06);
  --eo-chips-border-color: rgba(0, 0, 0, 0.1);
  --eo-chips-text-color: var(--text-color-body);

  --item-focus-background-color: var(--list-item-hover-background);

  --split-gutter-size-zero-background-color: var(--color-primary-3);
}
