.dark {
    --main-background: #e5e5e5;
    --main-background-light: #eeeeee;

    --panel-background: var(--color-primary);
    --panel-background-grey: rgb(55, 55, 55);
    --panel-background-lightgrey: rgb(58, 58, 58);

    --panel-header-border-bottom-color: rgba(0, 0, 0, 0.12);
    --list-item-hover-background: #eee;
    --list-item-selected-background: #f5f5f5;
    --list-item-border-color: rgba(255, 255, 255, 0.2);
    
    --text-color-body: #fff;
    --text-color-caption: rgba(255, 255, 255, 0.75);
    --text-color-hint: rgba(255, 255, 255, 0.5);


    --eo-chips-background-color: rgba(255, 255, 255, 0.1);
    --eo-chips-border-color: var(--text-color-hint);
    --eo-chips-text-color: var(--text-color-body);
}
