// TODO: remove this file once migration is done

.yvc-md-panel {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--panel-background-grey);
}

section[role="tabpanel"] {
    height: 100%;
    overflow: auto !important;
}
.eo-references {
    height: 100%;
}
yvc-tabs > header > .scroll-x {
    justify-content: flex-end;
}

yvc-split-tabs yvc-split-view {
    height: 100%;
}
.yvc-autocomplete yvc-typeahead-input {
    box-sizing: border-box;
}
.yvc-typeahead-input input {
    width: 100%
}
